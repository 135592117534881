@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.herobg {
  background-image: url("/src/assets/hero-image.webp");
  background-repeat: cover;
}

.popup {
  height: auto;
  width: auto;
  background: #000;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  border-radius: 10px;
}

button {
  transition: background-color 0.2s;
}

button:hover {
  background-color: #5a67d8;
}
